import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Link from "../components/link"
import SocialShare from "../components/social-share"
import { RiTimerLine } from "react-icons/ri"

export const blogPostQuery = graphql`
  query ($slug: String!) {
    blog: contentfulBlog(fields: { slug: { eq: $slug } }) {
      title
      image {
        gatsbyImageData(
          height: 400
          width: 600
          layout: FIXED
          placeholder: BLURRED
          formats: [WEBP, PNG]
        )
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            gatsbyImageData(placeholder: BLURRED, formats: [WEBP, PNG])
          }
        }
      }
      fields {
        readingTime {
          text
        }
        excerpt
      }
    }
  }
`

export default function BlogPost({
  data: {
    blog: { title, image, content, fields },
  },
  location,
}) {
  const postImage = getImage(image);
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => (
        <GatsbyImage
          image={postImage}
          placeholder="BLURRED"
          style={{ marginBottom: "3rem" }}
        />
      ),
      [INLINES.HYPERLINK]: node => (
        <Link to={node.data.uri}>{node.content[0]?.value}</Link>
      ),
    },
  }

  return (
    <BlogPostContainer>
      <Seo
        title={title}
        image={image.src}
        description={fields.excerpt}
        article={true}
      />
      <Image image={postImage} style={{ width: "100%" }} />
      <Meta>
        <SubTitle>
          <RiTimerLine /> {fields.readingTime.text}
        </SubTitle>
      </Meta>
      <h1>{title}</h1>
      <SocialShare
        title={title}
        url={location.href}
        description={fields.excerpt}
      />
      <Content>{renderRichText(content, options)}</Content>
    </BlogPostContainer>
  )
}

const BlogPostContainer = styled.div`
  margin: 4rem auto 7rem;
  @media screen and (max-width: 1150px) {
    margin: 5rem 5vw;
  }
  @media screen and (max-width: 480px) {
    margin: 4.5rem 4vw;
  }

  h1 {
    font-size: 3.3rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 720px) {
      font-size: 3rem;
      margin-bottom: 1.75rem;
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 2rem;
      font-size: 3.7rem;
    }
  }
`

const SubTitle = styled.h4`
  margin: 1rem 0;
  display: inline-flex;
  align-items: center;
  @media screen and (max-width: 720px) {
    margin: 1.75rem 0;
  }
  svg {
    margin-right: 0.8rem;
  }
`

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > *:last-child {
    margin-right: 1rem;
    margin-top: 0.5rem;
    font-size: 1.5rem;
    @media screen and (max-width: 480px) {
      font-size: 1.8rem;
      margin-top: 2rem;
    }
  }
`

const Image = styled(GatsbyImage)`
  height: 55rem;
  border-radius: 0.75rem;
  margin-bottom: 2rem;
  box-shadow: var(--shadow-mid);
  @media screen and (max-width: 780px) {
    height: 40vh;
    margin: 0;
    border-radius: 3px;
  }
`

const Content = styled.div`
  padding: 0 0.5rem;
  p {
    line-height: 2;
    margin-bottom: 3rem;
    text-align: justify;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  ol,
  ul {
    margin: 3rem 1.75rem;
    li {
      p {
        margin: 0.7rem 0;
      }
    }
  }
  blockquote {
    border-left: 1.5px solid var(--text-light);
    padding-left: 1.5rem;
    margin-left: 1rem;
    font-style: italic;
  }
  img {
    max-height: 50rem;
  }

  @media screen and (max-width: 480px) {
    padding: 0 1rem;
    p {
      font-size: 2rem;
    }
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2.8rem;
    }
    h3 {
      font-size: 2.6rem;
    }
    h4 {
      font-size: 2.4rem;
    }
    h5 {
      font-size: 2.25rem;
    }
    h6 {
      font-size: 2.1rem;
    }
  }
`
