import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"

export default function ShareButtons({ title, url, description }) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)
  return (
    <ShareButtonContainer>
      <FacebookShareButton url={url}>
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>

      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>

      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <EmailShareButton
        subject={title}
        body={`Hello, Checkout this content ${url}`}
      >
        <EmailIcon size={40} round={true} />
      </EmailShareButton>

      <LinkedinShareButton
        url={url}
        summary={description}
        source={siteMetadata.title}
      >
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>
    </ShareButtonContainer>
  )
}

const ShareButtonContainer = styled.div`
  margin: 2rem 0 3rem;
  button {
    margin-right: 1.75rem;
  }
  @media screen and (max-width: 520px) {
    svg {
      width: 32px;
      height: 32px;
    }
  }
  @media screen and (max-width: 480px) {
    margin: 3rem 0;
    button {
      margin-right: 1.25rem;
    }
    svg {
      width: 30px;
      width: 30px;
    }
  }
`
